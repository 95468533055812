import * as Sentry from '@sentry/react';

export default function initSentry() {
  console.log('env', process.env.REACT_APP_BUILD_ENV);
  Sentry.init({
    dsn:'https://aa015f19ab9bd9cc47c45d8e9f80a4a7@sentry.talview.org/18',
    // sample sessions when an error occurs.
    replaysOnErrorSampleRate:  1.0 ,
    replaysSessionSampleRate:  0.1,
    release: '1.0.0',
    environment: process.env.REACT_APP_BUILD_ENV,
    enabled:true,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
